import { useRef } from 'react'
import BenefitsClube from 'src/components/ClubComponents/BenefitsClube'
import FaqClubeDecathlon from 'src/components/ClubComponents/FaqClubeDecathlon'
import MainClubeDecathlon from 'src/components/ClubComponents/MainClubeDecathlon'
import Seo from 'src/components/seo/Seo'

const ClubeDecathlonInstitutional = () => {
  const faqRef = useRef<null | HTMLDivElement>(null)

  return (
    <>
      <Seo title="Clube | Decathlon" />
      <MainClubeDecathlon />
      <BenefitsClube reference={faqRef} />
      <FaqClubeDecathlon reference={faqRef} />
    </>
  )
}

export default ClubeDecathlonInstitutional

//
